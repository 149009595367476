<template>
  <div>
    <v-dialog
      v-model="showManual"
      :fullscreen="true"
    >
      <v-card
        height="100vh"
        style="border-radius: 0 !important;"
      >
        <div class="d-flex align-center mb-4">
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <iframe
          :src="url"
          frameborder="0"
          height="100%"
          scrolling="auto"
          width="100%"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import LogoDisplay from '@/views/widget/LogoDisplay'

  export default {
    name: 'UserManualPage',
    components: { LogoDisplay },
    data () {
      return {
        showManual: true,
        url: 'http://aadenmanual.aaden.online/',
      }
    },
    methods: {
      closeDialog () {
        this.showManual = false
      },
    },
  }
</script>

<style scoped>

</style>
